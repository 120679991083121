<template>
  <v-layout wrap>
    <v-flex xs6 class="user-info">
      <p><b>{{$t('booking.reservations.cu', locale)}}: </b>{{cu}}</p>
      <p><b>{{$t('booking.reservations.lmu', locale)}}: </b>{{lmu}}</p>
      <p v-if="item.ResourceItemID"><b>{{$t('booking.reservations.resource', locale)}}: </b>{{resourceName}}</p>
      <p v-if="item.FieldsValues.rate"><b>{{$t('booking.reservations.rate', locale)}}: </b>{{item.FieldsValues.rate}}</p>
    </v-flex>
    <v-flex xs6 class="user-info">
      <p><b>{{$t('booking.reservations.pax_', locale)}}: </b><span style="font-size: 2rem;">{{item.NumPax}}</span></p>
    </v-flex>
    <v-flex :xs6="!paxAsCount" :xs12="paxAsCount"  style="padding-right:5px">
      <v-card 
        class="pax"
      >
        <h2>
          {{$t('booking.reservations.owner', locale)}}
        </h2>
        <p v-for="ff, index2 of formFields.owner"
           :key="index2"
        >
        <b>{{ getTranslation(ff.label, locale) }}:</b> {{getValue(ff.key, item.FieldsValues.owner)}}
        </p>
      </v-card>
    </v-flex>
    <v-flex 
      v-if="!paxAsCount"
      xs6 style="padding-left:5px" 
    >
      <v-card 
        v-for="fv, index of item.FieldsValues.pax"
        :key="index"
        class="pax"
      >
        <h2>
          {{$t('booking.reservations.pax', locale)}} {{parseInt(index) + 1}}
        </h2>
        <p v-for="ff, index2 of formFields.pax"
           :key="index2"
        >
        <b>{{ getTranslation(ff.label, locale) }}:</b> {{getValue(ff.key, fv)}}
        </p>
      </v-card>
    </v-flex>
    <v-flex xs12 style="text-align: right; padding-bottom: 20px">
      <v-btn color="red" small
        v-if="item.MultiReservationID"
        @click="handleCancelMultiReservation(item.MultiReservationID)"
        style="margin-right:20px;"
        dark
      >
        {{ $t('booking.reservations.cancelMultiReservation', locale) }}
      </v-btn>
      <v-btn color="primary" small
        @click="handleEdit"
      >
        {{ $t('booking.reservations.editReservation', locale) }}
      </v-btn>
    </v-flex>
  </v-layout>
</template>
<script>
import axios from 'axios'
import api from '@/services/api'
import utils from '@/services/utils'
export default {
  props: {
    locale: {
      type: String,
      default: null,
    },
    item: {
      type: Object,
      required: true
    },
    onEdit: {
      type: Function,
      required: true,
    },
  },
  data: () =>  ({
    loading: false,
    formFields: [],
    cu: null,
    lmu: null,
    paxAsCount: null,
    resourceName: null,
  }),
  mounted() {
    this.handleGetData()
  },
  methods: {
    getTranslation (v, locale) {
      return utils.getTranslation(v, locale)
    },
    getValue(key, value) {
      return value[key] ? value[key] : ''
    },
    async getUser (userID, locale) {
      if (!userID) return this.$t('booking.reservations.owner', locale).toUpperCase()

      try {
        const resp = await axios.get(api.getAPIURL('system', `v1/internal/users/${userID}/name`))
        return resp.data && resp.data.name ? resp.data.name :this.$t('booking.reservations.owner', locale).toUpperCase()
      } catch (err) {
        console.log('err', err)
        return this.$t('booking.reservations.owner', locale).toUpperCase()
      }
    },
    prepareField (v) {
      if (this.editedItem && !this.isNew) {
        console.log('jhm', v)
      }
      if(v.type === 'select' && v.items && v.items.length > 0) {
        v.items = v.items.map(item => {
          item.name = utils.getTranslation(item.name, this.locale)
          return item
        })
      }
      return v
    },
    async handleGetData () {
      this.loading = true
      this.cu = await this.getUser(this.item.CU, this.locale)
      this.lmu = await this.getUser(this.item.LMU, this.locale)
      this.formFields = []
      if (!this.item) return

      // get form fields by eventID
      api.getAllWithoutLimit ('booking', `v1/public/events/${this.item.Event.ID}/${this.item.IsActtiv ? 'form-fields-acttiv' : 'form-fields' }`)
        .then(response => {
          if(!response) {
            this.loading = false
            return
          }
          this.paxAsCount = response.paxAsCount ? true : false

          if (response.owner) response.owner = response.owner.map(item => {
            item = this.prepareField(item)
            return item
          })
          if (response.pax) response.pax = response.pax.map(item => {
            item = this.prepareField(item)
            return item
          })
          this.formFields = response
        })
      if (this.item.ResourceItemID) this.handleGetResource(this.item.ResourceItemID)
    },
    handleEdit () {
      this.onEdit(this.item)
    },
    handleCancelMultiReservation(multiReservationID) {
      api.put ('booking', `v1/private/reservations/${multiReservationID}/cancel-multi`)
        .then(() => {
          this.$store.state.app.bookingRefreshReservationList = utils.getNewID()
        })
    },
    handleGetResource (v) {
      api.getItem ('booking', `v1/private/resources/${v}/resource-items/`, v)
        .then(response => {
          this.resourceName = response && response.Name ? utils.getTranslation(response.Name, this.locale) : ''
        })
    },
  },
}
</script>
<style>
.user-info {
  padding-top: 20px;
}
.user-info p {
  margin: 0;
}
.pax {
  padding: 10px;
  margin: 10px 0;
}
.pax h2 {
  text-align: center;
}
.pax p {
  margin:0;
}
</style>

