<template>
  <v-layout wrap>
    <v-flex xs8 style="padding: 20px 0 0 10px">
      <!--list
        v-if="workspaceID"
        :workspaceID="workspaceID"
        :selectedDate="selectedDate"
        :locale="locale"
        :onEdit="handleEditItem"
        :updateData="updateData"
      /-->
      <list
        v-if="workspaceID"
        :workspaceID="workspaceID"
        :locale="locale"
        :onEdit="handleEditItem"
        :updateData="updateData"
      />
    </v-flex>
    <v-flex xs4 style="padding: 20px 20px 0 20px">
      <v-card
        style="height:calc(100vh - 104px);overflow-y: auto;overflow-x: hidden;scrollbar-width: thin !important;padding: 15px"
        elevation="0"
      >
        <no-edited
          v-if="!editedID"
        />
        <relocate-form 
          v-else
          :editedID="editedID"
          :onClose="handleClose"
        />
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import utilsBooking from '@/services/bookings'
import List from '@/components/reservationsList/Index'
import RelocateForm from './RelocateForm'
import NoEdited from './NoEdited'
export default {
  components: {
    List,
    RelocateForm,
    NoEdited,
  },
  data: () => ({
    workspaceID: null,
    reservations: null,
    count: null,
    editedID: null,
    updateData: null,
  }),
  computed: {
    ...mapState('app',['locale', 'bookingWorkspace']),
  },
  watch: {
    bookingWorkspace (v) {
      this.workspaceID = v
    },
  },
  mounted () {
    this.workspaceID = this.bookingWorkspace
    this.handleGetData()
  },
  methods: {
    handleGetData () {
      api.getAll('booking', `v1/private/workspaces/${this.workspaceID}/relocate-event-reservations`)
        .then(response => {
          if (response) {
            this.count = response.count
            this.reservations = response.data
          }
        })
    },
    handleEditItem (v) {
      this.editedID = v
    },
    handleClose (update) {
      if (update) {
        this.updateData = {
          LMD: new Date().toISOString()
        }
        utilsBooking.handleCheckRelocateReservations('booking', this.workspaceID)
      }
      this.editedID = null
    },
  },
}
</script>

