<template>
  <v-app-bar :elevation="0" dense color="white"
    v-if="itemsPerPage !== null && count !== null && startIndex !== null"
  >
    <v-spacer />
    <span style="padding: 0 10px;">{{ info }}</span>
    <v-icon :disabled="disableStart" @click="handleChangePage(-itemsPerPage)">mdi-chevron-left</v-icon>
    <v-menu>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="primary"
          x-small
          v-if="onChangeItemsPerPage"
        >
        {{ itemsPerPage === 10000000 ? 'ALL' : itemsPerPage }}
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="item of pages"
          :key="item.id"
          @click="handleChangeItemsPerPage(item.id)"
        >
        <v-list-item-title><center>{{ item.name }}</center></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-icon :disabled="disableEnd" @click="handleChangePage(itemsPerPage)" style="margin-left: 0px">mdi-chevron-right</v-icon>
  </v-app-bar>
</template>

<script>
  export default {
    props: {
      startIndex: {
        type: Number,
        required: false,
        default: null
      },
      count: {
        type: Number,
        required: false,
        default: null
      },
      itemsPerPage: {
        type: Number,
        required: false,
        default: null
      },
      onGetData: {
        type: Function,
        required: true,
        default: null
      },
      onChangeItemsPerPage: {
        type: Function,
        default: null,
      },
      locale: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        info: '',
        disableStart: true,
        disableEnd: true,
      }
    },
    computed: {
      pages () { 
        return [
            {id: 5,name: 5},
            {id: 10,name: 10},
            {id: 15,name: 15},
            {id: 20,name: 20},
            {id: 25,name: 25},
            {id: 30,name: 30},
            {id: 500,name: 500},
            {id: 10000000,name: 'ALL'},
          ]
      },
    },
    watch: {
      itemsPerPage() {
        this.setInfo()
      },
      startIndex () {
        this.setInfo()
      },
      count () {
        this.setInfo()
      }
    },
    mounted () {
      this.setInfo()
    },
    methods: {
      setInfo () {
        const end = this.startIndex + this.itemsPerPage
        const start = this.startIndex
        this.disableStart = start <= 0
        this.disableEnd = end >= this.count
        this.info = `${start}-${end > this.count ? this.count : end} ${this.$t('common.of', this.locale)} ${this.count}`
      },
      handleChangePage (v) {
        const aux = this.startIndex + v
        this.onGetData(aux < 0 ? 0 : aux)
      },
      handleChangeItemsPerPage (v) {
        this.onChangeItemsPerPage(v === 'ALL' ? 10000000 : v)
      },
    }
  }
</script>

<style scoped>
  span {
    font-size: 0.75rem;
  }
</style>
