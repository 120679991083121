<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }" >
        <v-icon
          :color="item.Note ? 'green' : null"
          small
          v-bind="attrs"
          v-on="on"
          style="margin-top:5px;"
        >
          mdi-note-plus-outline
        </v-icon>
      </template>
      <v-card>
        <v-toolbar
          dark
          color="primary"
          elevation="0"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Nota</v-toolbar-title>
          <v-spacer/>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="handleSave"
            >
              {{ $t('common.save', locale)}}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <vue-editor
          v-model="note"
          :editor-toolbar="customToolbar"
        />
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
const fullToolbar = [
  // [{ font: ["arial"] }],
  // [{ font: [] }],
  // [{ font: [{ list: "ordered" }, { list: "bullet" }, { list: "check" }] }],
  [{ header: [false, 1, 2, 3, 4, 5, 6] }],
  [{ size: ["small", false, "large", "huge"] }],
  // [{ size: ["small", false, "large", "huge"] }],
  ["bold", "italic", "underline", "strike"],
  [
    { align: "" },
    { align: "center" },
    { align: "right" },
    { align: "justify" }
  ],
  [{ header: 1 }, { header: 2 }],
  ["blockquote", "code-block"],
  [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
  [{ script: "sub" }, { script: "super" }],
  [{ indent: "-1" }, { indent: "+1" }],
  [{ color: [] }, { background: [] }],
  ["link",
    // "image",
    // "formula"
  ],
  [{ direction: "rtl" }],
  ["clean"]
]

import { VueEditor } from 'vue2-editor'
import api from '@/services/api'

export default {
  components: {
    VueEditor,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    onRefresh: {
      type: Function,
      requried: true,
    },
  },
  data: () => ({
    dialog: false,
    note: null,
  }),
  computed: {
    customToolbar () {
      return fullToolbar
    },
  },
  watch: {
    'item.Note' () {
      this.prepareData()
    },
  },
  mounted () {
    this.prepareData()
  },
  methods: {
    prepareData() {
      this.note = this.item.Note ? JSON.parse(this.item.Note) : null
    },
    handleSave () {
      api.updateItem ('booking', `v1/private/reservations/`, this.item.ID.split('_').shift().concat('/note'), { note: this.note})
        .then(() => {
          this.onRefresh()
          this.dialog = false
        })
    },
  },
}
</script>

